<template>
    <div class="bg-raspberry">
        <img :src="require('@/assets/icons/accordion-arr-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/add-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/adv-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/arr-sm-down-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/arrow-left.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/arrow-right.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/avatar.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/back-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/bag-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/basket-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/blank-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/burger-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/burger.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/calendar.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/chat.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/checklist-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/checklist.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/clients.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/close-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/close-white-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/coin.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/deal-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/delegation-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/delete.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/blank-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/doc-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/documents.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/dots-horz.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/dropdown-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/dropdown-side-arr-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/eraser.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/error-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/eye-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/file-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/fire-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/floppy-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/folder.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/graph-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/graph.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/heart-fill.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/info-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/learning.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/mail-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/menu-black-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/metro-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/no-image.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/note-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/object.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/office.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/options-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/partner-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/pdf-file-white.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/pdf-file.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/pen-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/personal.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/phone-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/planning.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/request.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/reserve.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/schedule.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/search-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/search-white-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/search.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/send-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/setting.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/send-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/setting.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/slider-arr-w-left.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/slider-arr-w-right.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/spb-metro.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/staff-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/stage-move-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/staple-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/stay-home.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/tag-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/task-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/tg-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/transfer.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/turnOff-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/turnOff-red-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/user-card-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/user-card-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/vk.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/whatsapp-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/zoom-in.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/blank-gray-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/requisitions-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/requisitions-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/manager-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/manager-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/company-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/company-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/passport-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/passport-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/connect-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/connect-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/strategy-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/phone-call-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/blocked-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/blocked-red-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/info-card-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/info-card-green-icon.svg')" alt="..." width="40" height="40" class="m-10">
        <img :src="require('@/assets/icons/mountains-icon.svg')" alt="..." width="40" height="40" class="m-10">
    </div>
    
</template>